<template>
  <v-layout wrap class="ctk-footer-div">
    <v-footer class="ctk-footer">
      <template>
        <v-layout wrap>
          <v-flex xs12 class="ctk-footer-copyright">
            ©德国玛娅家居 版权所有
            <!--<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> {{ info.icp }}</a>-->
          </v-flex>
        </v-layout>
      </template>
    </v-footer>
  </v-layout>
</template>
<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      let links = []
      if (this.$store.getters.companyInfo.link) {
        links = this.$store.getters.companyInfo.link.filter(ele => ele.type === 0)
      }
      let info = this.$store.getters.companyInfo
      info.links = links
      return info
    }
  }
}
</script>
